import { createStore } from 'vuex'

export default createStore({
  state: {
    meta: null,
  },
  getters: {

  },
  mutations: {
    setMetaData (state, data) {
      state.meta = data;
    }
  },
  actions: {

  },
  modules: {

  }
})
