<template>

  <router-view/>
  
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    watch: {
      /* eslint-disable */
      $route (to, from) {
        // console.log('ROUTE to, from', to, from);
        
        let meta = this.$route.meta;
        // console.log('meta', meta);
        if (Object.keys(meta).length)
          this.$store.commit('setMetaData', meta);
        else
          this.$store.commit('setMetaData', null);
        
      }
    },
    created() {
      
    }

  }
</script>

<style lang="scss">
// @import "@/assets/scss/main.scss";


#app {
  font-family: $figtree;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: $lightgreen;
  min-height: 100vh;
  color: black;

}


</style>
